import { ref, computed, onMounted, reactive } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { MemberListEnum } from 'web/src/modules/referral-program/components/ReferralProgramMemberList/enums';
export default function useReferralProgramMemberList(props) {
    const { $translate } = useI18n();
    const isAnimated = ref(false);
    const isOpen = reactive({
        [MemberListEnum.AVAILABLE]: true,
        [MemberListEnum.WAITING]: !props.showBonusesList,
        [MemberListEnum.RECEIVED]: !props.showBonusesList
    });
    const blockRef = reactive({
        [MemberListEnum.AVAILABLE]: void 0,
        [MemberListEnum.WAITING]: void 0,
        [MemberListEnum.RECEIVED]: void 0
    });
    const blockHeight = reactive({
        [MemberListEnum.AVAILABLE]: 0,
        [MemberListEnum.WAITING]: 0,
        [MemberListEnum.RECEIVED]: 0
    });
    const blockStyle = computed(()=>({
            [MemberListEnum.AVAILABLE]: isOpen[MemberListEnum.AVAILABLE] ? `${blockHeight[MemberListEnum.AVAILABLE]}px` : 0,
            [MemberListEnum.WAITING]: isOpen[MemberListEnum.WAITING] ? `${blockHeight[MemberListEnum.WAITING]}px` : 0,
            [MemberListEnum.RECEIVED]: isOpen[MemberListEnum.RECEIVED] ? `${blockHeight[MemberListEnum.RECEIVED]}px` : 0
        }));
    const memberList = computed(()=>[
            {
                name: MemberListEnum.AVAILABLE,
                title: $translate('WEB2_REFERRAL_PROGRAM_AVAILABLE_BONUS').value,
                list: props.availableReferrals
            },
            {
                name: MemberListEnum.WAITING,
                title: $translate('WEB2_REFERRAL_PROGRAM_WAITING').value,
                list: props.pendingReferrals
            },
            {
                name: MemberListEnum.RECEIVED,
                title: $translate('WEB2_REFERRAL_PROGRAM_RECIVED').value,
                list: props.completedReferrals
            }
        ]);
    function calculateBlockHeight() {
        for (const name of Object.values(MemberListEnum)){
            const element = blockRef[name];
            if (element) blockHeight[name] = element.clientHeight || 0;
        }
    }
    function toggleOpen(name) {
        if (!isAnimated.value) isAnimated.value = true;
        const element = blockRef[name];
        if (element) blockHeight[name] = element.clientHeight || 0;
        isOpen[name] = !isOpen[name];
    }
    function setBlockRef(member) {
        return function setRef(el) {
            blockRef[member] = el;
        };
    }
    onMounted(calculateBlockHeight);
    return {
        isAnimated,
        isOpen,
        blockRef,
        blockStyle,
        memberList,
        toggleOpen,
        setBlockRef
    };
}
