import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "social-sharing-copy-link"
};
const _hoisted_2 = {
    key: 0,
    class: "social-sharing-copy-link__label"
};
const _hoisted_3 = {
    class: "social-sharing-copy-link__text"
};
const _hoisted_4 = {
    class: "social-sharing-copy-link__icon"
};
import { VIcon } from '@components/v-icon';
import { useVSocialSharingCopyLink } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSocialSharingCopyLink',
    props: {
        label: {},
        link: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { iconCopyProps } = useVSocialSharingCopyLink();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _ctx.label ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: "social-sharing-copy-link__block",
                    onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
                }, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.link), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconCopyProps))), null, 16)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'VSocialSharingCopyLink'
                ]
            ]);
        };
    }
});
