import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useVSocialSharing(props, emit) {
    const { $translate } = useI18n();
    const { copy } = useCopyToClipboard();
    const analytics = useAnalytics();
    const headerProps = {
        title: '',
        prefixIconName: void 0,
        suffixIconName: IconName.CROSS,
        type: 'tertiary'
    };
    const shareSocialImage = computed(()=>{
        let src;
        return src ? {
            src
        } : void 0;
    });
    const modalProps = computed(()=>({
            ...props.modalProperties,
            isProfileLayout: true,
            isInnerModal: true,
            isOverlayCloseAllowed: true,
            image: shareSocialImage.value
        }));
    const buttonProps = computed(()=>({
            action: DialogAction.MODAL_CLOSE,
            label: $translate('JSP_PCL_FBOT_CLOSE').value,
            fullWidth: true
        }));
    const handleCopy = ()=>{
        copy({
            data: props.url,
            notificationText: $translate('WEB2_LINK_HAS_BEEN_COPIED').value,
            isProfileModalTooltip: true
        });
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                mainPage: {
                    shareLinkForm: 'copyLink'
                }
            }
        });
    };
    function sendAnalytic(value) {
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                mainPage: {
                    shareLinkForm: {
                        icons: value
                    }
                }
            }
        });
    }
    function close(isCross) {
        emit('close');
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                mainPage: {
                    shareLinkForm: isCross ? 'cross' : 'close'
                }
            }
        });
    }
    return {
        modalProps,
        buttonProps,
        headerProps,
        handleCopy,
        sendAnalytic,
        close
    };
}
