import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps, renderList as _renderList, withModifiers as _withModifiers, vShow as _vShow } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import RouteName from '@leon-hub/routing-config-names';
import { ButtonKind } from '@leon-hub/module-buttons';
import { VIcon } from '@components/v-icon';
import ReferralProgramAddButton from 'web/src/modules/referral-program/components/ReferralProgramAddButton/ReferralProgramAddButton.vue';
import ReferralProgramAvatar from 'web/src/modules/referral-program/components/ReferralProgramAvatar/ReferralProgramAvatar.vue';
import ReferralProgramMemberList from 'web/src/modules/referral-program/components/ReferralProgramMemberList/ReferralProgramMemberList.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import ReferralProgramStatus from 'web/src/modules/referral-program/components/ReferralProgramStatus/ReferralProgramStatus.vue';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VReferralProgramStep from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/VReferralProgramStep.vue';
import VReferralProgramSelectBonus from 'web/src/modules/referral-program/components/ReferralProgramSelectBonus/VReferralProgramSelectBonus.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import ReferralProgramPopupListSteps from 'web/src/modules/referral-program/components/ReferralProgramPopupListSteps/ReferralProgramPopupListSteps.vue';
import VReferralProgramBanner from 'web/src/modules/referral-program/components/ReferralProgramBanner/VReferralProgramBanner.vue';
import VSocialSharing from 'web/src/components/SocialSharing/VSocialSharing/VSocialSharing.vue';
import VReferralProgramBlock from 'web/src/modules/referral-program/components/ReferralProgramBlock/VReferralProgramBlock.vue';
import SReferralProgramBlock from 'web/src/modules/referral-program/components/ReferralProgramBlock/SReferralProgramBlock.vue';
import SSocialSharingCopyLink from 'web/src/components/SocialSharing/SocialSharingCopyLink/SSocialSharingCopyLink.vue';
import SReferralProgramSelectBonus from 'web/src/modules/referral-program/components/ReferralProgramSelectBonus/SReferralProgramSelectBonus.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import SReferralProgramStep from 'web/src/modules/referral-program/components/ReferralProgramStep/SReferralProgramStep/SReferralProgramStep.vue';
import SReferralProgramBanner from 'web/src/modules/referral-program/components/ReferralProgramBanner/SReferralProgramBanner.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { useReferralProgramRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramRoutePage',
    setup (__props) {
        const { pendingReferralCount, referrals, viewMode, setViewMode, completedReferrals, pendingReferrals, availableReferrals, selectedReferral, selectedColorId, jumbotronProps, requirements, isConditionsSatisfied, bonuses, isContentLoading, isAccessGranted, playerRequirementsCompleted, playerRequirementsCurrent, isSelectedReferralCompleted, referralUrl, socialSharingNetworks, goToTerms, goToBonuses, handleShowShareModal, closeShareModal, isShareModal, clickOnReward, referralFiendsProps, referralBonusProps, handleBlockClick, isBonusSelected, selectedBonus, changeSelectedBonus } = useReferralProgramRoutePage();
        const showMoreIcon = IconName.EXPAND_RIGHT;
        const showMoreIconSize = IconSize.SIZE_16;
        function onReferralClick(item, colorId) {
            selectedReferral.value = item;
            selectedColorId.value = colorId;
            setViewMode(ReferralProgramViewMode.DETAILS);
        }
        return (_ctx, _cache)=>{
            _resolveComponent("router-link");
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createElementBlock(_Fragment, null, [
                _unref(isContentLoading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _withDirectives(_createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program'])
                    }, [
                        (_openBlock(), _createBlock(VReferralProgramBanner, {
                            key: 1,
                            onClick: _cache[2] || (_cache[2] = ($event)=>_unref(goToTerms)('banner'))
                        })),
                        _createCommentVNode("", true),
                        _unref(isAccessGranted) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 3
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['referral-program__content'])
                            }, [
                                _createCommentVNode("", true),
                                _createCommentVNode("", true),
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program__blocks'])
                                }, [
                                    (_openBlock(), _createBlock(VReferralProgramBlock, _mergeProps({
                                        key: 1
                                    }, _unref(referralFiendsProps), {
                                        onClick: _cache[4] || (_cache[4] = ($event)=>_unref(handleBlockClick)(_unref(ReferralProgramViewMode).LIST))
                                    }), null, 16)),
                                    (_openBlock(), _createBlock(VReferralProgramBlock, _mergeProps({
                                        key: 3
                                    }, _unref(referralBonusProps), {
                                        onClick: _cache[6] || (_cache[6] = ($event)=>_unref(handleBlockClick)(_unref(ReferralProgramViewMode).LIST_BONUSES))
                                    }), null, 16))
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'referral-program-blocks'
                                        }
                                    ]
                                ]),
                                _createCommentVNode("", true)
                            ], 2),
                            _unref(referrals).length > 0 ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['referral-program__header'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program__header-awaiting'])
                                }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_REFERRAL_PROGRAM_WAITING')) + ":", 1),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['referral-program__header-awaiting-count'])
                                    }, _toDisplayString(_unref(pendingReferralCount)), 3)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program__header-show']),
                                    onClick: _cache[9] || (_cache[9] = ($event)=>_unref(setViewMode)(_unref(ReferralProgramViewMode).LIST))
                                }, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_REFFERAL_SHOW_ALL')), 1),
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(showMoreIcon),
                                        size: _unref(showMoreIconSize)
                                    }, null, 8, [
                                        "name",
                                        "size"
                                    ])
                                ], 2)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'referral-program-header'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['referral-program__friends'])
                            }, [
                                _createCommentVNode("", true),
                                _createVNode(_unref(VSwiper), null, {
                                    default: _withCtx(()=>[
                                            _createVNode(ReferralProgramAddButton, {
                                                class: _normalizeClass(_ctx.$style['referral-program__friends-item']),
                                                onClick: _unref(handleShowShareModal)
                                            }, null, 8, [
                                                "class",
                                                "onClick"
                                            ]),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(referrals), (referral, index)=>(_openBlock(), _createBlock(ReferralProgramAvatar, {
                                                    key: referral.customerId,
                                                    "color-id": index % 10 + 1,
                                                    class: _normalizeClass(_ctx.$style['referral-program__friends-item']),
                                                    status: referral.status || void 0,
                                                    initials: referral.isIncognito ? void 0 : referral.initials,
                                                    amount: referral.amount ? referral.formattedAmount : void 0,
                                                    name: referral.name,
                                                    onClick: _withModifiers(($event)=>onReferralClick(referral, index % 10 + 1), [
                                                        "stop"
                                                    ])
                                                }, null, 8, [
                                                    "color-id",
                                                    "class",
                                                    "status",
                                                    "initials",
                                                    "amount",
                                                    "name",
                                                    "onClick"
                                                ]))), 128))
                                        ]),
                                    _: 1
                                })
                            ], 2),
                            _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['referral-program__terms']),
                                onClick: _cache[11] || (_cache[11] = ($event)=>_unref(goToTerms)('rules'))
                            }, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('REFERRAL_PROGRAM_INFO_TITLE')), 1),
                                _createElementVNode("div", null, [
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(showMoreIcon),
                                        size: _unref(showMoreIconSize)
                                    }, null, 8, [
                                        "name",
                                        "size"
                                    ])
                                ])
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'referral-program-terms'
                                    }
                                ]
                            ])
                        ], 64)) : (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: _normalizeClass(_ctx.$style['referral-program__get-access'])
                        }, [
                            _withDirectives((_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['referral-program__get-access-text'])
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_REFERRAL_PROGRAM_NO_ACCESS_MESSAGE')) + ": ", 1)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'referral-program-get-access-text'
                                    }
                                ]
                            ]),
                            _unref(playerRequirementsCompleted).length ? (_openBlock(), _createBlock(ReferralProgramPopupListSteps, {
                                key: 1,
                                title: _ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_DONE'),
                                satisfied: "",
                                green: "",
                                list: _unref(playerRequirementsCompleted)
                            }, null, 8, [
                                "title",
                                "list"
                            ])) : _createCommentVNode("", true),
                            _unref(playerRequirementsCurrent).length ? (_openBlock(), _createBlock(ReferralProgramPopupListSteps, {
                                key: 2,
                                title: _ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_CURRENT'),
                                list: _unref(playerRequirementsCurrent),
                                green: ""
                            }, null, 8, [
                                "title",
                                "list"
                            ])) : _createCommentVNode("", true)
                        ], 2))
                    ], 2), [
                        [
                            _vShow,
                            _unref(viewMode) === _unref(ReferralProgramViewMode).MAIN
                        ]
                    ]),
                    _unref(viewMode) === _unref(ReferralProgramViewMode).LIST || _unref(viewMode) === _unref(ReferralProgramViewMode).LIST_BONUSES ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['referral-program'])
                    }, [
                        _createVNode(ReferralProgramMemberList, {
                            "pending-referrals": _unref(pendingReferrals),
                            "available-referrals": _unref(availableReferrals),
                            "completed-referrals": _unref(completedReferrals),
                            "show-bonuses-list": _unref(viewMode) === _unref(ReferralProgramViewMode).LIST_BONUSES,
                            onClick: onReferralClick
                        }, null, 8, [
                            "pending-referrals",
                            "available-referrals",
                            "completed-referrals",
                            "show-bonuses-list"
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _unref(viewMode) === _unref(ReferralProgramViewMode).DETAILS ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['referral-program__details-wrapper'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program__details'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['referral-program__details-content'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program__details-name'])
                                }, _toDisplayString(_unref(selectedReferral)?.name), 3),
                                _unref(selectedReferral)?.status ? (_openBlock(), _createBlock(ReferralProgramStatus, {
                                    key: 0,
                                    status: _unref(selectedReferral).status,
                                    "is-badge-status": "",
                                    "is-label": ""
                                }, null, 8, [
                                    "status"
                                ])) : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program__details-date'])
                                }, _toDisplayString(_unref(selectedReferral)?.longDate), 3)
                            ], 2),
                            _createVNode(ReferralProgramAvatar, {
                                class: _normalizeClass(_ctx.$style['referral-program__details-avatar']),
                                "color-id": _unref(selectedColorId),
                                initials: _unref(selectedReferral)?.isIncognito ? void 0 : _unref(selectedReferral)?.initials,
                                "is-big": ""
                            }, null, 8, [
                                "class",
                                "color-id",
                                "initials"
                            ])
                        ], 2),
                        _unref(selectedReferral)?.status === _unref(ProgramParticipationStatus).AVAILABLE ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['referral-program__details-available'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['referral-program__details-available-inner'])
                            }, [
                                _createVNode(VJumbotron, _mergeProps(_unref(jumbotronProps), {
                                    class: _ctx.$style['referral-program__details-jumbotron']
                                }), null, 16, [
                                    "class"
                                ])
                            ], 2),
                            (_openBlock(true), _createElementBlock(_Fragment, {
                                key: 1
                            }, _renderList(_unref(bonuses), (bonus, index)=>(_openBlock(), _createBlock(VReferralProgramSelectBonus, {
                                    key: `bonus-${index}`,
                                    class: _normalizeClass(_ctx.$style['referral-program__select-bonus']),
                                    title: bonus.title,
                                    label: bonus.label,
                                    type: bonus.type,
                                    info: bonus.info,
                                    onClick: ($event)=>_unref(clickOnReward)(bonus.type, _unref(selectedReferral).customerId)
                                }, null, 8, [
                                    "class",
                                    "title",
                                    "label",
                                    "type",
                                    "info",
                                    "onClick"
                                ]))), 128))
                        ], 2)) : (_openBlock(), _createBlock(VJumbotron, _mergeProps({
                            key: 1
                        }, _unref(jumbotronProps), {
                            class: _ctx.$style['referral-program__details-jumbotron']
                        }), {
                            footer: _withCtx(()=>[
                                    _unref(isSelectedReferralCompleted) && _unref(isBonusSelected) ? (_openBlock(), _createBlock(LButton, {
                                        key: 1,
                                        "full-width": "",
                                        onClick: _unref(goToBonuses)
                                    }, {
                                        default: _withCtx(()=>[
                                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_BONUSES_TITLE')), 1)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "onClick"
                                    ])) : _createCommentVNode("", true),
                                    _unref(isSelectedReferralCompleted) && _unref(isBonusSelected) ? (_openBlock(), _createElementBlock("div", {
                                        key: 2,
                                        class: _normalizeClass(_ctx.$style['referral-program__details-footer'])
                                    }, _toDisplayString(_ctx.$t('WEB2_REFERRAL_GO_TO_BONUSES')), 3)) : _createCommentVNode("", true)
                                ]),
                            _: 1
                        }, 16, [
                            "class"
                        ])),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program__details-req-title'])
                        }, _toDisplayString(_unref(isConditionsSatisfied) ? _ctx.$t('WEB2_REFERRAL_CONDITIONS_MET') : _ctx.$t('WEB2_REFERRAL_CONDITIONS_NOT_MET')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program__details-req'])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, {
                                key: 1
                            }, _renderList(_unref(requirements), (requirement)=>(_openBlock(), _createBlock(VReferralProgramStep, {
                                    key: requirement.label,
                                    label: requirement.label,
                                    satisfied: requirement.satisfied,
                                    "is-info": ""
                                }, null, 8, [
                                    "label",
                                    "satisfied"
                                ]))), 128))
                        ], 2)
                    ], 2)) : _createCommentVNode("", true)
                ], 64)),
                _unref(isAccessGranted) && _unref(viewMode) === _unref(ReferralProgramViewMode).MAIN ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['referral-program__social-sharing'])
                }, [
                    _createVNode(VButton, {
                        label: _ctx.$t('WEB2_SHARE_LINK'),
                        "icon-name": _unref(IconName).SHARE,
                        "full-width": "",
                        onClick: _cache[12] || (_cache[12] = ($event)=>_unref(handleShowShareModal)(true))
                    }, null, 8, [
                        "label",
                        "icon-name"
                    ])
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'referral-program-social-sharing'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _createVNode(VSocialSharing, {
                    shown: _unref(isShareModal),
                    networks: _unref(socialSharingNetworks),
                    url: _unref(referralUrl),
                    onClose: _unref(closeShareModal)
                }, null, 8, [
                    "shown",
                    "networks",
                    "url",
                    "onClose"
                ])
            ], 64);
        };
    }
});
