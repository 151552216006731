import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { ProgramParticipationStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import ReferralProgramAvatar from 'web/src/modules/referral-program/components/ReferralProgramAvatar/ReferralProgramAvatar.vue';
import { useReferralProgramMemberItem } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramMemberItem',
    props: {
        status: {},
        amount: {},
        formattedAmount: {},
        date: {},
        initials: {},
        name: {},
        label: {},
        colorId: {},
        referralMemberName: {},
        isIncognito: {
            type: Boolean
        },
        playerReward: {},
        currentStep: {},
        totalSteps: {}
    },
    setup (__props) {
        const props = __props;
        const { label } = useReferralProgramMemberItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-member-item'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-member-item__left'])
                }, [
                    _createVNode(ReferralProgramAvatar, {
                        status: _ctx.status,
                        "color-id": _ctx.colorId,
                        initials: _ctx.isIncognito ? void 0 : _ctx.initials
                    }, null, 8, [
                        "status",
                        "color-id",
                        "initials"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-member-item__label'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program-member-item__member-name'])
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.referralMemberName), 1)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['referral-program-member-item__member-status']]: true,
                                [_ctx.$style['referral-program-member-item__member-status--available']]: _ctx.status === _unref(ProgramParticipationStatus).AVAILABLE,
                                [_ctx.$style['referral-program-member-item__member-status--completed']]: _ctx.status === _unref(ProgramParticipationStatus).COMPLETED
                            })
                        }, _toDisplayString(_unref(label)), 3)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-member-item__right'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['referral-program-member-item__amount']]: true,
                            [_ctx.$style['referral-program-member-item__amount--bonus']]: _ctx.status === _unref(ProgramParticipationStatus).AVAILABLE
                        })
                    }, [
                        _ctx.amount ? (_openBlock(), _createElementBlock("span", _hoisted_1, "+ " + _toDisplayString(_ctx.formattedAmount), 1)) : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-member-item__date'])
                    }, _toDisplayString(_ctx.date), 3)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramMemberItem'
                ]
            ]);
        };
    }
});
