import { ref } from 'vue';
export default function useShareModal() {
    const isShareModal = ref(false);
    const showShareModal = async (param)=>{
        let { title, url } = param;
        isShareModal.value = true;
    };
    function closeShareModal() {
        isShareModal.value = false;
    }
    return {
        isShareModal,
        showShareModal,
        closeShareModal
    };
}
