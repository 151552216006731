import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import useReferralTopBar from 'web/src/modules/referral-program/layouts/composables/useReferralTopBar';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramTopBarRouteComponent',
    props: {
        title: {}
    },
    setup (__props) {
        const props = __props;
        const prefixIcon = IconName.ARROW_LEFT;
        const { computedTitle, showPrefix, onBackClick, onClose, slotName, goToTerms } = useReferralTopBar(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(computedTitle),
                "custom-prefix-icon-name": _unref(prefixIcon),
                "hide-prefix": !_unref(showPrefix),
                "only-emit-events": "",
                onBack: _unref(onBackClick),
                onClose: _unref(onClose)
            }, _createSlots({
                _: 2
            }, [
                _unref(slotName) ? {
                    name: _unref(slotName),
                    fn: _withCtx(()=>[
                            //@ts-ignore
                            (_openBlock(), _createBlock(VButton, {
                                key: 1,
                                "icon-name": _unref(IconName).INFO_OUTLINE,
                                "icon-size": _unref(IconSize).SIZE_24,
                                kind: _unref(ButtonKind).TRANSPARENT,
                                onClick: _unref(goToTerms)
                            }, null, 8, [
                                "icon-name",
                                "icon-size",
                                "kind",
                                "onClick"
                            ]))
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "title",
                "custom-prefix-icon-name",
                "hide-prefix",
                "onBack",
                "onClose"
            ])), [
                [
                    _directive_auto_id,
                    'ReferralProgramTopBarRouteComponent'
                ]
            ]);
        };
    }
});
