import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
let ReferralProgramRegistrationPrefetch = class ReferralProgramRegistrationPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');
        if (!isProfileReferralProgramEnabled.value || !to.params.code) {
            next({
                name: RouteName.HOME
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { ReferralProgramRegistrationPrefetch as default };
