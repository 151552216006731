import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramAddButton',
    setup (__props) {
        const iconProperty = {
            size: IconSize.SIZE_24,
            name: IconName.PLUS_SMALL
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-add-button'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-add-button__circle'])
                }, [
                    _createVNode(_unref(VIcon), _mergeProps({
                        class: _ctx.$style['referral-program-add-button__icon']
                    }, iconProperty), null, 16, [
                        "class"
                    ])
                ], 2),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['referral-program-add-button__label'])
                }, _toDisplayString(_ctx.$t('WEB2_REFFERAL_PROGRAM_INVITE')), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramAddButton'
                ]
            ]);
        };
    }
});
