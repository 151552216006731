import { IconName, IconSize } from '@leon-hub/icons';
export default function useVSocialSharingCopyLink() {
    const iconCopyProps = {
        name: IconName.FILES,
        size: IconSize.SIZE_16
    };
    return {
        iconCopyProps
    };
}
