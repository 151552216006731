import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, Teleport as _Teleport, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonKind } from '@leon-hub/module-buttons';
import DefaultModal from 'web/src/components/Modal/DefaultModal/DefaultModal.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import VSocialSharingCopyLink from 'web/src/components/SocialSharing/SocialSharingCopyLink/VSocialSharingCopyLink.vue';
import VSocialSharingItem from 'web/src/components/SocialSharing/VSocialSharingItem/VSocialSharingItem.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import SSocialSharingCopyLink from 'web/src/components/SocialSharing/SocialSharingCopyLink/SSocialSharingCopyLink.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { useVSocialSharing } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSocialSharing',
    props: {
        shown: {
            type: Boolean
        },
        networks: {},
        url: {},
        modalProperties: {},
        showCloseButton: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const modalSelector = ModalSelector.DESKTOP_INNER_MODAL;
        const { modalProps, headerProps, buttonProps, handleCopy, sendAnalytic, close } = useVSocialSharing(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.shown ? _withDirectives((_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: _unref(modalSelector)
            }, [
                _createVNode(DefaultModal, _mergeProps(_unref(modalProps), {
                    class: _ctx.$style['social-sharing__modal'],
                    onClose: _unref(close),
                    onButtonClick: _cache[4] || (_cache[4] = ($event)=>_unref(close)(false))
                }), _createSlots({
                    header: _withCtx(()=>[
                            _createVNode(ModalWindowHeader, _mergeProps(_unref(headerProps), {
                                class: _ctx.$style['social-sharing__header'],
                                onPrefixClick: _cache[0] || (_cache[0] = ($event)=>_unref(close)(true)),
                                onSuffixClick: _cache[1] || (_cache[1] = ($event)=>_unref(close)(true))
                            }), null, 16, [
                                "class"
                            ])
                        ]),
                    content: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['social-sharing__content'])
                            }, [
                                _ctx.networks.length ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['social-sharing__title'])
                                    }, _toDisplayString(_ctx.$t('WEB2_SHARE_ON_SOCIAL_NETWORKS')), 3),
                                    _createVNode(_unref(VSwiper), {
                                        ref: "swiper",
                                        class: _normalizeClass(_ctx.$style['social-sharing__slider'])
                                    }, {
                                        default: _withCtx(()=>[
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                                        key: index,
                                                        class: _normalizeClass(_ctx.$style['social-sharing__slide'])
                                                    }, {
                                                        default: _withCtx(()=>[
                                                                network.status ? (_openBlock(), _createBlock(VSocialSharingItem, {
                                                                    key: 0,
                                                                    url: network.url,
                                                                    "url-for-sharing": _ctx.url,
                                                                    title: network.title || '',
                                                                    icon: network.iconName,
                                                                    name: network.name,
                                                                    "background-color": network.backgroundColor || '',
                                                                    description: network.description || '',
                                                                    hashtags: network.hashtags || '',
                                                                    media: network.media || '',
                                                                    quote: network.quote || '',
                                                                    status: network.status,
                                                                    "restricted-locales": network.restrictedLocales || [],
                                                                    class: _normalizeClass(_ctx.$style['social-sharing__network-item']),
                                                                    onClick: ($event)=>_unref(sendAnalytic)(network.name)
                                                                }, null, 8, [
                                                                    "url",
                                                                    "url-for-sharing",
                                                                    "title",
                                                                    "icon",
                                                                    "name",
                                                                    "background-color",
                                                                    "description",
                                                                    "hashtags",
                                                                    "media",
                                                                    "quote",
                                                                    "status",
                                                                    "restricted-locales",
                                                                    "class",
                                                                    "onClick"
                                                                ])) : _createCommentVNode("", true)
                                                            ]),
                                                        _: 2
                                                    }, 1032, [
                                                        "class"
                                                    ]))), 128))
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "class"
                                    ])
                                ], 64)) : _createCommentVNode("", true),
                                (_openBlock(), _createBlock(VSocialSharingCopyLink, {
                                    key: 2,
                                    link: _ctx.url,
                                    label: _ctx.$t('WEB2_COPY_AND_SEND_LINK_TO_FRIEND'),
                                    onClick: _unref(handleCopy)
                                }, null, 8, [
                                    "link",
                                    "label",
                                    "onClick"
                                ]))
                            ], 2)
                        ]),
                    _: 2
                }, [
                    _ctx.showCloseButton ? {
                        name: "footer",
                        fn: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['social-sharing__footer'])
                                }, [
                                    (_openBlock(), _createBlock(LButton, _mergeProps({
                                        key: 1
                                    }, _unref(buttonProps), {
                                        kind: _unref(ButtonKind).SECONDARY,
                                        onClick: _cache[3] || (_cache[3] = ($event)=>_unref(close)(false))
                                    }), null, 16, [
                                        "kind"
                                    ]))
                                ], 2)
                            ]),
                        key: "0"
                    } : void 0
                ]), 1040, [
                    "class",
                    "onClose"
                ])
            ], 8, [
                "to"
            ])), [
                [
                    _directive_auto_id,
                    'VSocialSharing'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
