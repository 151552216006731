import { computed } from 'vue';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { getRewardTitle } from 'web/src/modules/referral-program/utils/referralProgramPopup';
export default function useReferralProgramMemberItem(props) {
    const { $translate } = useI18n();
    const { locale } = useI18nStore();
    const label = computed(()=>{
        if (props.status === ProgramParticipationStatus.AVAILABLE) return $translate('WEB2_REFERRAL_PROGRAM_CHOOSE_BONUS').value;
        if (props.status === ProgramParticipationStatus.PENDING) return `${$translate('WEB2_REFERRAL_PROGRAM_CONDITIONS').value} ${props.currentStep}/${props.totalSteps}`;
        if (props.playerReward) return getRewardTitle(props.playerReward, $translate, locale);
        return '';
    });
    const classObject = computed(()=>({
            'referral-program-member-item': true,
            [`referral-program-member-item--${props.status}`]: props.status !== ProgramParticipationStatus.PENDING
        }));
    return {
        label,
        classObject
    };
}
