import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VReferralProgramBanner',
    setup (__props) {
        const { $translate } = useI18n();
        const title = computed(()=>$translate('WEB2_REFERRAL_PROGRAM_BANNER_TEXT').value);
        let imgSrc;
        // eslint-disable-next-line import/no-dynamic-require
        imgSrc = require('web/src/modules/referral-program/image/banner.png');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program__wrapper'])
            }, [
                _createElementVNode("img", {
                    class: _normalizeClass(_ctx.$style['referral-program__image']),
                    src: _unref(imgSrc)
                }, null, 10, _hoisted_1),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['referral-program__text'])
                }, _toDisplayString(title.value), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VReferralProgramBanner'
                ]
            ]);
        };
    }
});
