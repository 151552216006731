import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import useVSocialSharingItem from './composables/useVSocialSharingItem';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSocialSharingItem',
    props: {
        urlForSharing: {},
        popup: {
            default: ()=>({
                    width: 626,
                    height: 436
                })
        },
        name: {},
        url: {},
        title: {},
        description: {
            default: ''
        },
        quote: {},
        hashtags: {
            default: ''
        },
        media: {},
        backgroundColor: {
            default: 'rgba(38, 211, 103, 1)'
        },
        icon: {},
        status: {
            type: Boolean
        },
        restrictedLocales: {}
    },
    emits: [
        "change",
        "open",
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { networkName, handleClick, iconName } = useVSocialSharingItem(props, emit);
        const iconSize = IconSize.SIZE_52;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                class: _normalizeClass({
                    [_ctx.$style['social-sharing-item']]: true,
                    [_ctx.$style[`social-sharing-item--${_unref(networkName)}`]]: !!_unref(networkName)
                }),
                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(handleClick) && _unref(handleClick)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['social-sharing-item__icon']),
                    style: _normalizeStyle({
                        background: _ctx.backgroundColor
                    })
                }, [
                    _ctx.icon ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 0,
                        name: _unref(iconName),
                        size: _unref(iconSize)
                    }, null, 8, [
                        "name",
                        "size"
                    ])) : _createCommentVNode("", true)
                ], 6),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['social-sharing-item__name'])
                }, _toDisplayString(_unref(networkName)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSocialSharingItem'
                ]
            ]);
        };
    }
});
