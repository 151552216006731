import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import { useReferralProgramStatus } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramStatus',
    props: {
        status: {},
        isLabel: {
            type: Boolean
        },
        isBadgeStatus: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProperty, statusLabel, badgeKind } = useReferralProgramStatus(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass({
                        [_ctx.$style['referral-program-status']]: true,
                        [_ctx.$style['referral-program-status--has-label']]: _ctx.isLabel
                    })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['referral-program-status__content']]: true,
                            [_ctx.$style[`referral-program-status__content--${_ctx.status.toLowerCase()}`]]: true
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program-status__round-container'])
                        }, [
                            _createVNode(_unref(VIcon), _mergeProps({
                                class: _ctx.$style['referral-program-status__icon']
                            }, _unref(iconProperty)), null, 16, [
                                "class"
                            ])
                        ], 2),
                        _ctx.isLabel ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['referral-program-status__label'])
                        }, _toDisplayString(_unref(statusLabel)), 3)) : _createCommentVNode("", true)
                    ], 2)
                ], 2))
            ])), [
                [
                    _directive_auto_id,
                    'ReferralProgramStatus'
                ]
            ]);
        };
    }
});
