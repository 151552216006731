import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useReferralProgramStatus(props) {
    const { $translate } = useI18n();
    const statusIcon = computed(()=>{
        if (props.status === ProgramParticipationStatus.PENDING) return IconName.CLOCK;
        if (props.status === ProgramParticipationStatus.AVAILABLE) return IconName.PROMOS;
        return IconName.CHECK;
    });
    const iconProperty = computed(()=>({
            size: props.isBadgeStatus ? IconSize.SIZE_20 : IconSize.SIZE_12,
            name: statusIcon.value
        }));
    const statusLabel = computed(()=>{
        if (props.status === ProgramParticipationStatus.PENDING) return $translate('WEB2_REFFERAL_PROGRAM_WAITING').value;
        if (props.status === ProgramParticipationStatus.AVAILABLE) return $translate('WEB2_REFFERAL_PROGRAM_BONUS').value;
        return $translate('WEB2_REFFERAL_PROGRAM_READY').value;
    });
    const badgeKind = computed(()=>{
        if (props.status === ProgramParticipationStatus.PENDING) return 'additional-3';
        if (props.status === ProgramParticipationStatus.AVAILABLE) return 'additional-2';
        return 'brand';
    });
    return {
        iconProperty,
        statusLabel,
        statusIcon,
        badgeKind
    };
}
