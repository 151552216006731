import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
let ReferralProgramPrefetch = class ReferralProgramPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');
        if (!isProfileReferralProgramEnabled.value) {
            next({
                name: RouteName.ERROR_FORBIDDEN_403
            });
            return;
        }
        const referralStore = useReferralProgramStore();
        await referralStore.getAvailability();
        const availability = toRef(referralStore, 'availability');
        if (!availability.value?.available) {
            next({
                name: RouteName.ERROR_FORBIDDEN_403
            });
            return;
        }
        next();
    }
};
export { ReferralProgramPrefetch as default };
