import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VReferralProgramBlock',
    props: {
        title: {
            default: ''
        },
        value: {
            default: ''
        },
        iconName: {
            default: IconName.PEOPLE
        },
        iconSize: {
            default: IconSize.SIZE_32
        },
        isClickable: {
            type: Boolean
        },
        color: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function handleClick() {
            emit('click');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['referral-program-block']]: true,
                    [_ctx.$style['referral-program-block--clickable']]: _ctx.isClickable
                }),
                onClick: handleClick
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-block__labels'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['referral-program-block__title'])
                    }, _toDisplayString(_ctx.title), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['referral-program-block__value'])
                    }, _toDisplayString(_ctx.value), 3)
                ], 2),
                _createVNode(_unref(VIcon), {
                    class: _normalizeClass(_ctx.$style['referral-program-block__icon']),
                    name: _ctx.iconName,
                    size: _ctx.iconSize
                }, null, 8, [
                    "class",
                    "name",
                    "size"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VReferralProgramBlock'
                ]
            ]);
        };
    }
});
