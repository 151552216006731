import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useReferralProgramSelectBonus } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VReferralProgramSelectBonus',
    props: {
        type: {},
        title: {},
        label: {},
        info: {},
        isSelected: {
            type: Boolean
        },
        withoutLabel: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { toggleOpen, isOpen, blockRef, blockStyle } = useReferralProgramSelectBonus();
        function emitClick(event) {
            emit('click', event);
        }
        const infoButtonProperty = {
            kind: ButtonKind.TRANSPARENT,
            iconName: IconName.INFO_OUTLINE,
            iconSize: IconSize.SIZE_24
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['referral-program-select-bonus']]: true,
                    [_ctx.$style['referral-program-select-bonus--open']]: _unref(isOpen)
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-select-bonus__top-block'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-select-bonus__column'])
                    }, [
                        _createVNode(VButton, _mergeProps(infoButtonProperty, {
                            onClick: _unref(toggleOpen)
                        }), null, 16, [
                            "onClick"
                        ]),
                        _createElementVNode("div", null, [
                            _createElementVNode("p", {
                                class: _normalizeClass(_ctx.$style['referral-program-select-bonus__label'])
                            }, _toDisplayString(_ctx.title), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['referral-program-select-bonus__count'])
                            }, _toDisplayString(_ctx.label), 3)
                        ])
                    ], 2),
                    _createVNode(VButton, {
                        label: _ctx.$t('WEB2_REFERRAL_PROGRAM_CHOOSE_BONUS_2'),
                        onClick: emitClick
                    }, null, 8, [
                        "label"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-select-bonus__bottom-container']),
                    style: _normalizeStyle(_unref(blockStyle))
                }, [
                    _createElementVNode("div", {
                        ref_key: "blockRef",
                        ref: blockRef,
                        class: _normalizeClass(_ctx.$style['referral-program-select-bonus__bottom-block'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['referral-program-select-bonus__bottom-label'])
                        }, _toDisplayString(_ctx.info), 3)
                    ], 2)
                ], 6)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VReferralProgramSelectBonus'
                ]
            ]);
        };
    }
});
