import { computed, onMounted, onUnmounted, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ProgramRewardType, ProgramParticipationStatus } from '@leon-hub/api-sdk/src/sdk/sdk';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import getFirstLetter from 'web/src/modules/referral-program/utils/getFirstLetter';
import DateTime from 'web/src/utils/DateTime';
import { useFormatMoney } from 'web/src/modules/money/composables';
import getRewardProps from 'web/src/modules/referral-program/utils/getRewardProps';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useShareModal from 'web/src/components/SocialSharing/VSocialSharing/composables/useShareModal';
import isIncognito from 'web/src/modules/referral-program/utils/isIncognito';
import { ProfileBonusesActiveTab } from 'web/src/modules/profile/submodules/bonuses/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import getRequirementsTitle from '../../../utils/referralProgramPopup/getRequirementsTitle';
import getRequirementsRoute from '../../../utils/referralProgramPopup/getRequirementsRoute';
import getRewardTitle from '../../../utils/referralProgramPopup/getRewardTitle';
export default function useReferralProgramRoutePage() {
    const { $translate } = useI18n();
    const i18nStore = useI18nStore();
    const router = useRouter();
    const referralStore = useReferralProgramStore();
    const customerDataStore = useCustomerDataStore();
    const siteConfigStore = useSiteConfigStore();
    const formatMoney = useFormatMoney();
    const analytics = useAnalytics();
    const isConfirmed = toRef(referralStore, 'isConfirmed');
    const availability = toRef(referralStore, 'availability');
    const programDetails = toRef(referralStore, 'programDetails');
    const viewMode = toRef(referralStore, 'viewMode');
    const customerConfig = toRef(customerDataStore, 'customerConfig');
    const isAccessGranted = toRef(referralStore, 'isAccessGranted');
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const selectedBonus = ref(null);
    const isContentLoading = ref(true);
    const selectedReferral = ref(null);
    const selectedColorId = ref(0);
    const isBonusSelected = ref(false);
    const socialSharingNetworks = computed(()=>siteConfigStore.referralProgramSocialItems || []);
    const totalReward = toRef(referralStore, 'totalReward');
    const changeSelectedBonus = (value)=>{
        selectedBonus.value = value;
    };
    const referrals = computed(()=>(programDetails.value?.referrals ?? []).map((referral)=>({
                ...referral,
                initials: `${getFirstLetter(referral.firstName)}${getFirstLetter(referral.lastName)}`,
                isIncognito: isIncognito(referral),
                name: isIncognito(referral) ? $translate('WEB2_REFERRAL_PROGRAM_INCOGNITO').value : `${referral.firstName} ${getFirstLetter(referral.lastName)}.`,
                date: DateTime.withTimeStamp(referral.registrationDate).toFullDate(),
                longDate: useDateLocalizers().toFullLocaleDate(referral.registrationDate),
                formattedAmount: formatMoney(referral.playerReward?.amount || referral.playerRewardOptions[0]?.amount || 0),
                amount: referral.playerReward?.amount || referral.playerRewardOptions[0]?.amount || 0
            })));
    const completedReferrals = computed(()=>referrals.value.filter((referral)=>referral.status === ProgramParticipationStatus.COMPLETED));
    const availableReferrals = computed(()=>referrals.value.filter((referral)=>referral.status === ProgramParticipationStatus.AVAILABLE));
    const pendingReferrals = computed(()=>referrals.value.filter((referral)=>referral.status === ProgramParticipationStatus.PENDING).map((referral)=>({
                ...referral,
                currentStep: referral.requirements.filter((requirement)=>requirement.satisfied).length,
                totalSteps: referral.requirements.length
            })));
    const pendingReferralCount = computed(()=>pendingReferrals.value.length);
    const referralsCount = computed(()=>`${availability.value?.referralCount || 0}`);
    const bonuses = computed(()=>(selectedReferral.value?.playerRewardOptions ?? []).map((bonus)=>({
                ...getRewardProps(bonus, $translate, formatMoney, false)
            })));
    const playerRequirementsCompleted = computed(()=>(programDetails.value?.playerRequirements ?? []).filter((requirement)=>requirement.satisfied).map((requirement)=>({
                ...requirement,
                label: getRequirementsTitle(requirement, $translate, formatMoney)
            })));
    const playerRequirementsCurrent = computed(()=>(programDetails.value?.playerRequirements ?? []).filter((requirement)=>!requirement.satisfied).map((requirement)=>({
                ...requirement,
                label: getRequirementsTitle(requirement, $translate, formatMoney),
                to: getRequirementsRoute(requirement.type, homePageType.value)
            })));
    const requirements = computed(()=>(selectedReferral.value?.requirements ?? []).map((requirement)=>({
                ...requirement,
                label: getRequirementsTitle(requirement, $translate, formatMoney)
            })));
    const isConditionsSatisfied = computed(()=>requirements.value.every((requirement)=>requirement.satisfied));
    const playerRewardTParams = computed(()=>{
        const playerReward = selectedReferral.value?.playerReward;
        return {
            count: playerReward ? getRewardTitle(playerReward, $translate, i18nStore.locale) : '0',
            amount: formatMoney(playerReward?.nominalValue || 0)
        };
    });
    const rewardCountTParams = computed(()=>({
            count: selectedReferral.value?.referralReward ? getRewardTitle(selectedReferral.value?.referralReward, $translate, i18nStore.locale) : '0'
        }));
    const availableAmountTParams = computed(()=>({
            value: formatMoney((selectedReferral.value?.playerRewardOptions ?? [])[0]?.amount || 0)
        }));
    const isSelectedReferralCompleted = computed(()=>selectedReferral.value?.status === ProgramParticipationStatus.COMPLETED);
    const jumbotronProps = computed(()=>{
        const selectedStatus = selectedReferral.value?.status ?? ProgramParticipationStatus.PENDING;
        switch(selectedStatus){
            case ProgramParticipationStatus.COMPLETED:
                return {
                    icon: IconName.PROMOS,
                    image: void 0,
                    heading: $translate('WEB2_REFERRAL_COMPLETED_HEADING', playerRewardTParams).value,
                    text: $translate('WEB2_REFERRAL_COMPLETED_TEXT', rewardCountTParams).value
                };
            case ProgramParticipationStatus.AVAILABLE:
                return {
                    heading: $translate('WEB2_REFFERAL_PROGRAM_BONUS').value,
                    text: $translate('WEB2_REFERRAL_AVAILABLE_TEXT', availableAmountTParams).value
                };
            default:
                return {
                    heading: $translate('WEB2_REFERRAL_PENDING_HEADING').value,
                    text: $translate('WEB2_REFERRAL_PENDING_TEXT').value
                };
        }
    });
    const referralUrl = computed(()=>programDetails.value?.referralUrl || '');
    const referralFiendsProps = computed(()=>({
            title: $translate('WEB2_REFERRAL_FRIENDS').value,
            iconName: IconName.PEOPLE,
            value: referralsCount.value,
            isClickable: true,
            color: 'brand'
        }));
    const referralBonusProps = computed(()=>({
            title: $translate('WEB2_REFERRAL_MY_BONUS').value,
            value: totalReward.value,
            iconName: IconName.PROMOS,
            isClickable: true,
            color: 'additional'
        }));
    function pushToOnboardingPage(newValue) {
        isConfirmed.value = newValue.confirmed;
        if (isConfirmed.value) {
            if (customerConfig.value) isContentLoading.value = false;
        } else router.replace({
            name: RouteName.ONBOARDING,
            params: {
                id: OnboardingIdsEnum.REFERRAL_PROGRAM
            }
        });
    }
    function sendAnalyticOnOpen() {
        let openSection = isAccessGranted.value ? 'main' : 'noAccessPage';
        if (!isConfirmed.value && !isAccessGranted.value) openSection = 'accept';
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                openSection
            }
        });
    }
    function goToTerms(param) {
        router.push({
            name: RouteName.REFERRAL_PROGRAM_INFO
        });
        const analyticKey = viewMode.value === ReferralProgramViewMode.MAIN ? 'mainPage' : 'noAccessPage';
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                [analyticKey]: {
                    info: param
                }
            }
        });
    }
    function goToBonuses() {
        const rewardType = selectedReferral.value?.playerReward?.rewardType;
        let category = null;
        if (rewardType) category = rewardType === ProgramRewardType.FREEBET ? ProfileBonusesActiveTab.SPORT : ProfileBonusesActiveTab.GAME;
        router.push({
            name: RouteName.BONUSES,
            query: {
                category
            }
        });
    }
    watch(programDetails, (newValue)=>{
        if (newValue) pushToOnboardingPage(newValue);
    }, {
        immediate: true
    });
    const { isShareModal, showShareModal, closeShareModal } = useShareModal();
    async function clickOnReward(type, referralId) {
        if (type) {
            await referralStore.rewarding({
                type,
                referralId
            });
            const refId = selectedReferral.value?.customerId;
            selectedReferral.value = referrals.value.find((item)=>item.customerId === refId) ?? null;
            isBonusSelected.value = true;
        }
    }
    const handleShowShareModal = (isSendAnalytic)=>{
        if (isSendAnalytic) analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                mainPage: {
                    shareLink: 'shareLinkButton'
                }
            }
        });
        showShareModal({
            title: $translate('WEB2_SHARE_LINK_WINDOW_TITLE').value,
            url: referralUrl.value
        });
    };
    const handleBlockClick = (value)=>{
        if (0 === referrals.value.length) handleShowShareModal();
        else if (availableReferrals.value.length) referralStore.setViewMode(value);
        else referralStore.setViewMode(ReferralProgramViewMode.LIST);
        const analyticKey = value === ReferralProgramViewMode.LIST_BONUSES ? 'myBonus' : 'friends';
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                mainPage: {
                    [analyticKey]: 0 === referrals.value.length ? 'shareLink' : 'friendsList'
                }
            }
        });
    };
    onMounted(async ()=>{
        await referralStore.getRefDetails();
        await referralStore.getAvailability();
        await referralStore.setProgramReferral();
        sendAnalyticOnOpen();
    });
    onUnmounted(()=>{
        selectedReferral.value = null;
        selectedColorId.value = 0;
        referralStore.setViewMode(ReferralProgramViewMode.MAIN);
    });
    return {
        referralsCount,
        referrals,
        completedReferrals,
        availableReferrals,
        pendingReferrals,
        viewMode,
        totalReward,
        jumbotronProps,
        pendingReferralCount,
        selectedReferral,
        selectedColorId,
        setViewMode: referralStore.setViewMode,
        requirements,
        isConditionsSatisfied,
        bonuses,
        isContentLoading,
        pushToOnboardingPage,
        isAccessGranted,
        playerRequirementsCompleted,
        playerRequirementsCurrent,
        isSelectedReferralCompleted,
        referralUrl,
        socialSharingNetworks,
        goToTerms,
        goToBonuses,
        handleShowShareModal,
        closeShareModal,
        isShareModal,
        clickOnReward,
        referralFiendsProps,
        referralBonusProps,
        isBonusSelected,
        handleBlockClick,
        sendAnalyticOnOpen,
        selectedBonus,
        changeSelectedBonus
    };
}
