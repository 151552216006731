import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import ReferralProgramStatus from 'web/src/modules/referral-program/components/ReferralProgramStatus/ReferralProgramStatus.vue';
import { MascotName } from 'web/src/components/Logo/VLogo/enums';
import VLogo from 'web/src/components/Logo/VLogo/VLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramAvatar',
    props: {
        colorId: {},
        isBig: {
            type: Boolean
        },
        name: {},
        initials: {},
        amount: {},
        status: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['referral-program-avatar']]: true,
                    [_ctx.$style['referral-program-avatar--big']]: _ctx.isBig,
                    [_ctx.$style[`referral-program-avatar--${_ctx.colorId}`]]: true
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-avatar__circle'])
                }, [
                    _ctx.initials ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['referral-program-avatar__initials'])
                    }, _toDisplayString(_ctx.initials), 3)) : (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        _createVNode(VLogo, {
                            class: _normalizeClass(_ctx.$style['referral-program-avatar__mascot']),
                            "mascot-name": _unref(MascotName).MASCOT_REFERRAL,
                            height: _ctx.isBig ? 50 : 40
                        }, null, 8, [
                            "class",
                            "mascot-name",
                            "height"
                        ])
                    ])),
                    _ctx.status ? (_openBlock(), _createBlock(ReferralProgramStatus, {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['referral-program-avatar__status']),
                        status: _ctx.status,
                        "is-badge-status": false
                    }, null, 8, [
                        "class",
                        "status",
                        "is-badge-status"
                    ])) : _createCommentVNode("", true)
                ], 2),
                !_ctx.isBig && _ctx.name ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['referral-program-avatar__label'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-avatar__info'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['referral-program-avatar__name'])
                        }, _toDisplayString(_ctx.name), 3),
                        _ctx.amount ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['referral-program-avatar__amount'])
                        }, "+" + _toDisplayString(_ctx.amount), 3)) : _createCommentVNode("", true)
                    ], 2)
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramAvatar'
                ]
            ]);
        };
    }
});
