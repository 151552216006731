import { ref, computed, onMounted } from 'vue';
export default function useReferralProgramSelectBonus() {
    const isOpen = ref(false);
    const blockRef = ref();
    const blockHeight = ref(0);
    function calculateBlockHeight() {
        if (blockRef.value) blockHeight.value = blockRef.value.clientHeight || 0;
    }
    onMounted(calculateBlockHeight);
    function toggleOpen() {
        if (blockRef.value) blockHeight.value = blockRef.value?.clientHeight || 0;
        isOpen.value = !isOpen.value;
    }
    const blockStyle = computed(()=>({
            height: isOpen.value ? `${blockHeight.value}px` : 0
        }));
    return {
        toggleOpen,
        isOpen,
        blockRef,
        blockHeight,
        blockStyle
    };
}
