import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk';
import { VIcon } from '@components/v-icon';
import ReferralProgramMemberItem from 'web/src/modules/referral-program/components/ReferralProgramMemberList/ReferralProgramMemberItem.vue';
import { useReferralProgramMemberList } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramMemberList',
    props: {
        pendingReferrals: {},
        availableReferrals: {},
        completedReferrals: {},
        showBonusesList: {
            type: Boolean
        },
        open: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isAnimated, isOpen, blockStyle, memberList, toggleOpen, setBlockRef } = useReferralProgramMemberList(props);
        const toggleIcon = {
            name: IconName.EXPAND_UP,
            size: IconSize.SIZE_16
        };
        function onReferralClick(item, colorId) {
            emits('click', item, colorId);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-member-list'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(memberList), (data)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                        data.list.length ? (_openBlock(), _createElementBlock("div", {
                            key: data.name,
                            class: _normalizeClass({
                                [_ctx.$style['referral-program-member-list__item']]: true,
                                [_ctx.$style['referral-program-member-list__item--flexible']]: true,
                                [_ctx.$style['referral-program-member-list__item--open']]: _unref(isOpen)[data.name],
                                [_ctx.$style['referral-program-member-list__item--animated']]: _unref(isAnimated)
                            }),
                            onClick: ($event)=>_unref(toggleOpen)(data.name)
                        }, [
                            _createElementVNode("p", {
                                class: _normalizeClass(_ctx.$style['referral-program-member-list__item-title'])
                            }, [
                                _createElementVNode("span", null, _toDisplayString(data.title), 1),
                                _createVNode(_unref(VIcon), _mergeProps({
                                    class: _ctx.$style['referral-program-member-list__item-toggle'],
                                    ref_for: true
                                }, toggleIcon), null, 16, [
                                    "class"
                                ])
                            ], 2),
                            _createElementVNode("div", {
                                style: _normalizeStyle({
                                    height: _unref(blockStyle)[data.name]
                                }),
                                class: _normalizeClass(_ctx.$style['referral-program-member-list__item-container'])
                            }, [
                                _createElementVNode("div", {
                                    ref_for: true,
                                    ref: _unref(setBlockRef)(data.name),
                                    class: _normalizeClass(_ctx.$style['referral-program-member-list__item-body'])
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.list, (item, index)=>(_openBlock(), _createBlock(ReferralProgramMemberItem, {
                                            key: item.customerId,
                                            "color-id": index % 10 + 1,
                                            status: item.status || _unref(ProgramParticipationStatus).PENDING,
                                            "formatted-amount": item.formattedAmount,
                                            amount: item.amount,
                                            date: item.date,
                                            "referral-member-name": item.name,
                                            "player-reward": item.playerReward,
                                            "is-incognito": item.isIncognito,
                                            "total-steps": item.status === _unref(ProgramParticipationStatus).PENDING ? item.totalSteps : void 0,
                                            "current-step": item.status === _unref(ProgramParticipationStatus).PENDING ? item.currentStep : void 0,
                                            initials: item.initials,
                                            onClick: _withModifiers(($event)=>onReferralClick(item, index % 10 + 1), [
                                                "stop"
                                            ])
                                        }, null, 8, [
                                            "color-id",
                                            "status",
                                            "formatted-amount",
                                            "amount",
                                            "date",
                                            "referral-member-name",
                                            "player-reward",
                                            "is-incognito",
                                            "total-steps",
                                            "current-step",
                                            "initials",
                                            "onClick"
                                        ]))), 128))
                                ], 2)
                            ], 6)
                        ], 10, _hoisted_1)) : _createCommentVNode("", true)
                    ], 64))), 256))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramMemberList'
                ]
            ]);
        };
    }
});
