import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useReferralTopBar(props) {
    const analytics = useAnalytics();
    const { $translate } = useI18n();
    const referralStore = useReferralProgramStore();
    const router = useRouter();
    const viewMode = toRef(referralStore, 'viewMode');
    const isAccessGranted = toRef(referralStore, 'isAccessGranted');
    function onBackClick() {
        if (viewMode.value === ReferralProgramViewMode.DETAILS) referralStore.setViewMode(ReferralProgramViewMode.LIST);
        else // eslint-disable-next-line max-len
        if (viewMode.value === ReferralProgramViewMode.LIST || viewMode.value === ReferralProgramViewMode.LIST_BONUSES) referralStore.setViewMode(ReferralProgramViewMode.MAIN);
        else router.back();
    }
    const slotName = computed(()=>{
        if (!isAccessGranted.value) return 'prefix';
        return null;
    });
    function goToTerms() {
        router.push({
            name: RouteName.REFERRAL_PROGRAM_INFO
        });
        analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
            referFriendProgram: {
                noAccessPage: {
                    info: 'infoButton'
                }
            }
        });
    }
    function onClose() {
        router.closeModal();
    }
    const showPrefix = computed(()=>{
        "1";
        return viewMode.value !== ReferralProgramViewMode.MAIN;
    });
    return {
        viewMode,
        onBackClick,
        onClose,
        showPrefix,
        slotName,
        goToTerms,
        computedTitle: computed(()=>{
            if (viewMode.value === ReferralProgramViewMode.LIST || viewMode.value === ReferralProgramViewMode.LIST_BONUSES) return $translate('WEB2_REFERRAL_INVITED_FRIENDS').value;
            if (viewMode.value === ReferralProgramViewMode.DETAILS) return '';
            return props.title;
        })
    };
}
